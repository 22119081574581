<template>
  <div>
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModalImages"
      :closeOnBackdrop="false"
      size="lg"
      class="modal-extended"
      @update:show="$emit('Close')"
    >
    
    <CRow>
      <CCol lg="12" class="text-center" v-show="ImageList.length != 0">
        <CCarousel 
          v-if="ImageList.length !=0"
          arrows
          animate
          height="400px"
          controls
          ref="myCarousel"
        >            
          <CCarouselItem v-for="item in ImageList">
            <CRow>
              <CCol sm="12" class="d-flex justify-content-center align-items-center">
                <b>{{`${$t('label.Seal')} ${item.seal}`}}</b>
              </CCol>
            </CRow>
            <img :src="checkRoute(item)" width="500" height="400"/>
          </CCarouselItem>
        </CCarousel>
      </CCol>
      <CCol md="12" lg="12" class="text-center" v-show="ImageList.length == 0">
        <CCarousel 
          arrows
          height="400px"
          controls
          class="mb-5"
        >   
        </CCarousel>
      </CCol>
    </CRow>

      <template #footer>
        <CButton color="wipe"  @click="$emit('Close');">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import General from '@/_mixins/general';

function data() {
  return {
    showModalImages: false,
    ImageList: [],
  };
}

function checkRoute(item){
  return item.SealRoute ? this.$store.getters['connection/getBase']+(item.SealRoute.replace(/Public/i, '')).toLowerCase() : '/img/errorimage.jpg';
}

export default {
  name: 'modal-seal-images',
  components: {
  },
  data,
  props: {
    modal: Boolean, 
    Images: {
			type: Array,
      required: true,
      default: () => [],
		},
    titleModal: {
			type: String,
      required: true,
      default: () => '',
		},
  },
  mixins: [General],
  methods: {
    checkRoute,
  },
  watch: {
    modal: function(val){
      this.showModalImages = val;
      if (val) {
        this.ImageList = this.Images;
      } else {
        this.ImageList = [];
      }  
    },
  },
};
</script>
<style>
  .carousel-control-next-icon{
    background-color: #707079;
    border-radius: 15px;
    padding: 15%;
  }

  .carousel-control-prev-icon{
    background-color: #707079;
    border-radius: 15px;
    padding: 15%;
  }
</style>