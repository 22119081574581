var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==0),expression:"showIndex==0"}]},[_c('div',{staticClass:"align-items-center justify-content-center mx-1 my-3",staticStyle:{"background-color":"white","color":"#828c9c","height":"auto"}},[_c('CRow',[_c('CCol',{staticClass:"align-items-center justify-content-center",attrs:{"sm":"6"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12","xl":"12"}},[_c('b',[_vm._v(_vm._s(`${_vm.$t('label.inspections')} / ${_vm.$t('label.Seals')}`))])])],1)],1),(_vm.ValidateTpEirTransacId)?_c('CCol',{attrs:{"sm":"6"}},[_c('CRow',[_c('CCol',{staticClass:"align-items-center",attrs:{"sm":"12","xl":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.inspection'),
                  placement: 'top-end'
                }),expression:"{\n                  content: $t('label.inspection'),\n                  placement: 'top-end'\n                }"}],staticClass:"d-flex align-items-center ml-auto",attrs:{"shape":"square","color":"add"},on:{"click":function($event){_vm.showIndex=1}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nueva')))])],1)],1)],1)],1):_vm._e()],1)],1),_c('div',[_c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","details":"","column-filter":"","items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText},scopedSlots:_vm._u([{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(_vm._s(item.Status))])],1)]}},{key:"button",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                              content: `${_vm.$t('label.edit')} ${_vm.$t('label.Seals')}`,
                              placement: 'top-start'
                            }),expression:"{\n                              content: `${$t('label.edit')} ${$t('label.Seals')}`,\n                              placement: 'top-start'\n                            }"}],staticClass:"mr-1",attrs:{"color":"edit","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleAdd(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),(_vm.viewBottom(item))?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                              content: `${_vm.$t('label.images')}`,
                              placement: 'top-start'
                            }),expression:"{\n                              content: `${$t('label.images')}`,\n                              placement: 'top-start'\n                            }"}],attrs:{"color":"watch","square":"","size":"sm"},on:{"click":function($event){return _vm.ViewImages(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1):_vm._e()],1)]}}])})],1)],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==1),expression:"showIndex==1"}]},[_c('SealRegister',{attrs:{"Active":_vm.showIndex==1,"isEdit":_vm.isEdit,"SealItem":_vm.SealItem},on:{"Close":function($event){(_vm.showIndex=0, _vm.SealItem={}, _vm.isEdit=false)},"Update-list":_vm.UpdateData}})],1),_c('ModalImagesList',{attrs:{"modal":_vm.ModalImages,"Images":_vm.ImagesList,"titleModal":_vm.ImagenModalTitle},on:{"Close":function($event){_vm.ModalImages=false,_vm.ImagesList=[],_vm.ImagenModalTitle=''}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }